import React from "react";
import classNames from "classnames";
import styles from "./Leaf.module.scss";
import PropTypes from "prop-types";

export const Leaf = ({
    percentage = 0,
    activeColor = "#009448",
    inactiveColor = "#A4DDBC",
    backgroundColor = null,
    title,
    onClick,
    plain,
    ...props
}) => {
    if (plain) {
        return (
            <svg
                id="a"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="17.1 22.3 101.9 86.4"
                className={classNames(styles.leaf, props.className)}
                {...props}
            >
                <path
                    className="b"
                    fill="currentColor"
                    d="M79.46,108.81c-18.83,1.13-36.5-7.37-44.54-19.79-5.63-8.69-5.74-7.93-8.68-19.02l15.21,22.53c-12.76-10.3-18.75-25.34-16.18-40.98,.55-3.31,1.81-6.95,4.93-8.21,3.69-1.5,8.03,1.18,10.44,4.49,2.52,3.45,3.85,7.64,6.68,10.87,.26,.3,.59,.54,.96,.68,1.75,.62,2.75-1.55,2.93-3.24,.9-8.37-.92-17.4,2.68-24.92,1.05-2.19,2.73-4.29,5.14-4.82,2.08-.45,4.29,.35,6.13,1.51,2.21,1.39,4.1,3.3,5.44,5.52,1.3,2.15,2.11,4.59,4.12,6.32,.6,.52,1.31,.93,2.07,1.14,2.94,.8,4.87-1.46,6.41-3.51l5.62-7.47c2.63-3.5,5.98-7.34,10.48-7.1,4.98,.27,8.49,5.87,8.09,10.75-.43,5.26-4.08,9.6-7.72,13.26-.47,.48-.86,1.04-1.07,1.68-.94,2.95,2.15,4.58,4.7,5.36,4.03,1.22,8.27,2.17,11.68,4.67,1.37,1.01,2.6,2.29,3.29,3.82,1.95,4.33-1,9.4-5.16,11.45-4.17,2.05-8.96,2.96-13.7,2.74-4.15-.19-6.73-1.38-10.39-.01-.87,.32-2.42,2.63-2.1,3.51,1.92,5.34,8.18,3.54,10.48,7.21,2.61,4.18,1.39,9.38-1.08,13.43-1.92,3.15-5.78,5.25-9.2,6.72-5.44,2.34-10.72,1.19-16.74,.59-7.47-.74-12.11-3.65-17.03-6.76-3.87-2.45-7.29-5.31-11.22-8.17l3.44-2.42"
                />
                <path
                    className="b"
                    fill="currentColor"
                    d="M40.16,88.33s-4.38,6.42-11.84,8.52c0,0-3.77,1.07-5.97,1.07,0,0-2.66-.19-4.01,1.96,0,0-1.44,1.58-.51,4.1,0,0,.61,2.1,2.56,2.75,0,0,.62,.43,3.19,.14,0,0,6.03-.48,10.34-2.51,0,0,7.62-2.64,13.31-10.38l-7.08-5.65Z"
                />
            </svg>
        );
    }

    if (percentage > 1) {
        percentage = 1;
    }

    if (percentage < 0) {
        percentage = 0;
    }

    if (isNaN(percentage)) {
        percentage = 0;
    }

    const x = 17.1;
    const y = 22.3;
    const padding = 2;
    const colorPadding = 0;
    const viewBoxWidth = 101.5;
    const viewBoxHeight = 86.4;
    const greenSectionWidth = viewBoxWidth * percentage;

    const viewBox = `${x - padding} ${y - padding} ${
        viewBoxWidth + padding * 2
    } ${viewBoxHeight + padding * 2}`;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            className={classNames(styles.leaf, props.className)}
            onClick={onClick}
            alt={title}
            data-tip={title}
            {...props}
        >
            <defs>
                <mask id="myLeafMask" maskUnits="objectBoundingBox">
                    <rect
                        x={x - padding}
                        y={y - padding}
                        width={viewBoxWidth + padding * 2}
                        height={viewBoxHeight + padding * 2}
                        fill="white"
                    />
                    <path
                        fill="black"
                        className="b"
                        d="M79.1,108.41c-18.83,1.13-36.5-7.37-44.54-19.79-5.63-8.69-5.74-7.93-8.68-19.02l15.21,22.53c-12.76-10.3-18.75-25.34-16.18-40.98,.55-3.31,1.81-6.95,4.93-8.21,3.69-1.5,8.03,1.18,10.44,4.49,2.52,3.45,3.85,7.64,6.68,10.87,.26,.3,.59,.54,.96,.68,1.75,.62,2.75-1.55,2.93-3.24,.9-8.37-.92-17.4,2.68-24.92,1.05-2.19,2.73-4.29,5.14-4.82,2.08-.45,4.29,.35,6.13,1.51,2.21,1.39,4.1,3.3,5.44,5.52,1.3,2.15,2.11,4.59,4.12,6.32,.6,.52,1.31,.93,2.07,1.14,2.94,.8,4.87-1.46,6.41-3.51l5.62-7.47c2.63-3.5,5.98-7.34,10.48-7.1,4.98,.27,8.49,5.87,8.09,10.75-.43,5.26-4.08,9.6-7.72,13.26-.47,.48-.86,1.04-1.07,1.68-.94,2.95,2.15,4.58,4.7,5.36,4.03,1.22,8.27,2.17,11.68,4.67,1.37,1.01,2.6,2.29,3.29,3.82,1.95,4.33-1,9.4-5.16,11.45-4.17,2.05-8.96,2.96-13.7,2.74-4.15-.19-6.73-1.38-10.39-.01-.87,.32-2.42,2.63-2.1,3.51,1.92,5.34,8.18,3.54,10.48,7.21,2.61,4.18,1.39,9.38-1.08,13.43-1.92,3.15-5.78,5.25-9.2,6.72-5.44,2.34-10.72,1.19-16.74,.59-7.47-.74-12.11-3.65-17.03-6.76-3.87-2.45-7.29-5.31-11.22-8.17l3.44-2.42"
                    />
                    <path
                        fill="black"
                        className="b"
                        d="M39.8,87.93s-4.38,6.42-11.84,8.52c0,0-3.77,1.07-5.97,1.07,0,0-2.66-.19-4.01,1.96,0,0-1.44,1.58-.51,4.1,0,0,.61,2.1,2.56,2.75,0,0,.62,.43,3.19,.14,0,0,6.03-.48,10.34-2.51,0,0,7.62-2.64,13.31-10.38l-7.08-5.65Z"
                    />
                </mask>
            </defs>
            <rect
                x={x - colorPadding}
                y={y - colorPadding}
                width={viewBoxWidth + colorPadding * 2}
                height={viewBoxHeight + colorPadding * 2}
                fill={inactiveColor}
            />
            <rect
                x={x - colorPadding}
                y={y + colorPadding}
                width={greenSectionWidth}
                height={viewBoxHeight + colorPadding * 2}
                fill={activeColor}
            />
            <rect
                x={x - padding}
                y={y - padding}
                width={500}
                height={500}
                mask="url(#myLeafMask)"
                fill={backgroundColor || "currentColor"}
            />
        </svg>
    );
};

Leaf.propTypes = {
    /** The percentage value of how much of the leaf should be covered in the activeColor */
    percentage: PropTypes.number,

    /** The color that grows in size with percentage */
    activeColor: PropTypes.string,

    /** The default background color for the leaf when no percentage = 0 */
    inactiveColor: PropTypes.string,

    /** The background color of the leaf. The background color can also be set in `style.color` */
    backgroundColor: PropTypes.string,

    /** The title of the Leaf */
    title: PropTypes.string,

    /** Only return the SVG, without calculating the percentage */
    plain: PropTypes.bool,

    /** Class Names to apply */
    className: PropTypes.string,

    /** The style of the Leaf*/
    style: PropTypes.string,

    /** This Leaf has been clicked */
    onClick: PropTypes.func,
};
