const REVIEWS = [
    {
        review: "Laufið er stórskemmtilegt verkfæri til að halda utan um stöðuna á aðgerðum Hópbíla og veitir gott aðhald í að gera sífellt betur í sjálfbærnimálum.",
        reviewerName: "Guðjón Árnason",
        reviewerTitle: "Umhverfisstjóri Hópbíla",
    },
    {
        review: "Við erum raunverulega búin að bíða í þó nokkuð mörg ár eftir hugbúnaði eins og Laufinu. Hugbúnaði til að halda utanum um sjálfbærnivegferð leikskólans sem byggir á grænum skrefum Umhverfisstofnunar og Grænfánans.",
        reviewerName: "Leikskólinn Norðurberg",
    },
    {
        review: "Margt smátt gerir eitt stórt. Laufið hjálpar okkur að halda faglega utan um sjálfbærnimálin okkar.",
        reviewerName: "Ístex",
    },
];

export default REVIEWS;
