import React from "react";

export default function FlexContainer({children, justifyContent, alignItems, column, gap, ...props}) {
    
    return (
        <div
            {...props}
            style={{
                display: "flex",
                gap: gap,
                flexDirection: column ? "column" : undefined,
                justifyContent: justifyContent,
                alignItems: alignItems,
                ...props.style,
            }}
        >
            {children}
        </div>
    );
}
