import { THEME_COLOR } from "@utils/Constants";
import React from "react";
import FlexContainer from "@components/FlexContainer/FlexContainer";
import UserIcon from "@components/Icons/UserIcon";
import InstitutionIcon from "@components/Icons/InstitutionIcon";
import AccomplishmentIcon from "@components/Icons/AccomplishmentIcon";
import CountUp from "react-countup";
import styles from "./StatsSection.module.scss";

export const StatsSection = ({ statistics }) => {
    if (!statistics) {
        return null;
    }
    return (
        <FlexContainer
            style={{
                background: "linear-gradient( #fff,#D3FFE2)",
                padding: "50px 0px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <FlexContainer
                style={{
                    flex: 1,
                    maxWidth: 1200,
                    justifyContent: "center",
                }}
            >
                <FlexContainer
                    className={styles.statsContainer}
                    justifyContent="space-between"
                    style={{
                        flex: 1,
                    }}
                >
                    <Statistic
                        icon={<UserIcon height={65} width={65} />}
                        title="notendur"
                        value={statistics?.users || 0}
                    />
                    <Statistic
                        icon={<InstitutionIcon height={80} width={80} />}
                        title="stofnanir"
                        value={statistics?.institutions || 0}
                    />
                    <Statistic
                        icon={<AccomplishmentIcon height={70} width={70} />}
                        title="meðalárangur"
                        value={statistics?.average_green_steps || 0}
                        postValue="%"
                    />
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    );
};

const Statistic = ({ icon, title, preValue, value, postValue }) => {
    return (
        <FlexContainer
            column
            justifyContent="center"
            alignItems="center"
            style={{ width: "9.5rem", alignSelf: "center" }}
        >
            <FlexContainer
                style={{
                    height: 80,
                    width: 80,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {icon}
            </FlexContainer>
            <div
                style={{
                    color: THEME_COLOR,
                    textAlign: "center",
                    fontSize: 40,
                    fontWeight: 500,
                    marginBottom: 0,
                }}
            >
                <CountUp
                    prefix={preValue}
                    suffix={postValue}
                    end={value}
                    duration={3}
                    startOnMount={false}
                    separator="."
                    enableScrollSpy
                    scrollSpyOnce
                    scrollSpyDelay={100}
                    style={{
                        color: THEME_COLOR,
                        textAlign: "center",
                        fontSize: 40,
                    }}
                />
            </div>
            <div
                style={{
                    color: THEME_COLOR,
                    textAlign: "center",
                    fontSize: 22,
                    marginTop: -8,
                }}
            >
                {title}
            </div>
        </FlexContainer>
    );
};
