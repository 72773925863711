import * as React from "react";
const UserIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 85 87"
        fill="none"
        {...props}
    >
        <path
            fill="#009448"
            d="M18.917 23.817a24.365 24.365 0 0 1 .115-2.334 24.287 24.287 0 0 1 1.682-6.776 24.355 24.355 0 0 1 1.556-3.127 24.124 24.124 0 0 1 1.284-1.944 23.23 23.23 0 0 1 1.464-1.805 23.435 23.435 0 0 1 3.416-3.135 23.709 23.709 0 0 1 2.922-1.875 23.22 23.22 0 0 1 3.162-1.422 23.984 23.984 0 0 1 2.209-.68c.373-.094.748-.18 1.126-.257A23.238 23.238 0 0 1 42.44 0c31.09 1.113 31.152 46.42.08 47.63a23.677 23.677 0 0 1-3.458-.25 23.296 23.296 0 0 1-3.386-.762c-.37-.112-.735-.234-1.1-.364a23.7 23.7 0 0 1-4.18-1.992 23.91 23.91 0 0 1-2.838-2.016 24.313 24.313 0 0 1-1.716-1.569 23.57 23.57 0 0 1-2.269-2.654 23.229 23.229 0 0 1-1.86-2.964 23.854 23.854 0 0 1-2.338-6.589 24.108 24.108 0 0 1-.458-4.653Zm47.33 23.825a3.739 3.739 0 0 0-1-.437 3.733 3.733 0 0 0-1.085-.131 3.79 3.79 0 0 0-2.055.67 32.08 32.08 0 0 1-2.171 1.634 32.505 32.505 0 0 1-3.497 2.08 31.18 31.18 0 0 1-5.018 2.033 31.172 31.172 0 0 1-7.986 1.348 30.623 30.623 0 0 1-4.055-.118 30.37 30.37 0 0 1-2.68-.371 30.356 30.356 0 0 1-2.639-.604 31.182 31.182 0 0 1-10.847-5.43c-.358-.278-.71-.562-1.056-.855a3.501 3.501 0 0 0-2.513-.174c-.275.084-.536.2-.784.35-.723.485-1.43.995-2.122 1.526a42.398 42.398 0 0 0-7.446 7.334 42.886 42.886 0 0 0-6.464 11.38 42.885 42.885 0 0 0-2.123 7.6A43.64 43.64 0 0 0 0 83.341a3.67 3.67 0 0 0 .275 1.4 3.632 3.632 0 0 0 .783 1.188 3.618 3.618 0 0 0 1.85 1c.233.048.469.071.706.071h77.771a3.552 3.552 0 0 0 1.383-.278 3.565 3.565 0 0 0 1.173-.793 3.706 3.706 0 0 0 .99-1.874c.045-.236.069-.473.069-.714a43.561 43.561 0 0 0-.718-7.846 43.504 43.504 0 0 0-3.12-10.008 42.214 42.214 0 0 0-1.149-2.359 42.914 42.914 0 0 0-5.944-8.598 42.917 42.917 0 0 0-7.821-6.889Z"
        />
    </svg>
);
export default UserIcon;
