import { ContentContainer } from "@components/ContentContainer/ContentContainer";
import { THEME_COLOR } from "@utils/Constants";
import React from "react";
import { Title } from "@components/Title/Title";
import FlexContainer from "@components/FlexContainer/FlexContainer";
import Link from "next/link";

const CUSTOMERS = [
    {
        name: "Hópbílar",
        logo: "/img/companies/hopbilar.png",
        company_id: 216,
    },
    {
        name: "Elko",
        logo: "/img/companies/elko.png",
        company_id: 185,
    },
    {
        name: "Lopi",
        logo: "/img/companies/lopi.png",
        company_id: 54,
    },
    {
        name: "Steypustöðin",
        logo: "/img/companies/steypustodin.png",
        company_id: 5,
    },
    {
        name: "Samkaup",
        logo: "/img/companies/samkaup.png",
        company_id: 2,
    },
];

export const CustomersSection = () => {
    return (
        <div
            style={{
                width: "100%",
                maxWidth: 1200,
                marginBottom: 100,
                background: "white",
            }}
        >
            <Title
                size={2}
                style={{
                    color: THEME_COLOR,
                    fontWeight: 600,
                    textAlign: "center",
                    marginBottom: 80,
                }}
            >
                Brot af viðskiptavinum
            </Title>
            <FlexContainer
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: 1500,
                    padding: "0 50px",
                    flexWrap: "wrap",
                    gap: 50,
                }}
            >
                {CUSTOMERS.map((customer, index) => (
                    <Link
                        key={index}
                        href={`/vidskiptavinir/${customer.company_id}`}
                    >
                        <img
                            src={customer.logo}
                            style={{
                                cursor: "pointer",
                                width: "9vw",
                                minWidth: "120px",
                                maxWidth: "180px",
                            }}
                        />
                    </Link>
                ))}
            </FlexContainer>
        </div>
    );
};
