import React from "react";
import FlexContainer from "@components/FlexContainer/FlexContainer";
import styles from "./ReviewsSection.module.scss";
import { Carousel } from "react-bootstrap";
import REVIEWS from "@utils/Reviews";

export const ReviewsSection = () => {
    return (
        <>
            <div className={styles.container}>
                <Carousel
                    indicators={false}
                    interval={10000}
                    data-bs-theme="dark"
                >
                    {REVIEWS.map((review, index) => (
                        <Carousel.Item key={index}>
                            <Review {...review} />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </>
    );
};

const Review = ({ review, reviewerName, reviewerTitle }) => {
    return (
        <FlexContainer
            column
            style={{
                width: "100vw",
                minHeight: 300,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{ maxWidth: 750, paddingLeft: 75, paddingRight: 75 }}>
                {review && <p className={styles.review}>„{review}“</p>}
                {reviewerName && (
                    <p className={styles.reviewerName}>{reviewerName}</p>
                )}
                {reviewerTitle && (
                    <p className={styles.reviewerTitle}>{reviewerTitle}</p>
                )}
            </div>
        </FlexContainer>
    );
};
