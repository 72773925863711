import * as React from "react";
const InstitutionIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 109 92"
        fill="none"
        width={109}
        height={92}

        {...props}
    >
        <ellipse
            cx={51.612}
            cy={39.277}
            fill="#009448"
            rx={10.323}
            ry={9.819}
        />
        <mask
            id="institution"
            width={109}
            height={92}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: "luminance",
            }}
        >
            <path fill="#fff" d="M0 0h108.765v91.305H0V0Z" />
        </mask>
        <g mask="url(#institution)">
            <path
                fill="#009448"
                d="M73.597 58.772h-5.566V53.2h5.566v5.572Zm0 11.663h-5.566v-5.573h5.566v5.573Zm0 11.662h-5.566v-5.573h5.566v5.573ZM51.75 48.827c-5.116 0-9.263-4.15-9.263-9.27 0-5.12 4.147-9.269 9.263-9.269 5.114 0 9.262 4.149 9.262 9.27 0 5.119-4.148 9.27-9.262 9.27Zm9.136 9.945h-5.567V53.2h5.567v5.572Zm0 11.663h-5.567v-5.573h5.567v5.573Zm0 11.662h-5.567v-5.573h5.567v5.573Zm-12.71-23.325h-5.567V53.2h5.567v5.572Zm0 11.663h-5.567v-5.573h5.567v5.573Zm0 11.662h-5.567v-5.573h5.567v5.573Zm-12.71-23.325h-5.569V53.2h5.57v5.572Zm0 11.663h-5.569v-5.573h5.57v5.573Zm0 11.662h-5.569v-5.573h5.57v5.573Zm71.712 6.079h-11.48V46.383H85.33v41.793h-3.124V24.613H53.383v-8.757c7.76 4.928 12.04-7.84 20.214-.908V2.306c-8.175-6.933-12.453 5.835-20.214.906V1.761a1.562 1.562 0 1 0-3.123 0v22.852H21.293v63.563h-3.125V46.383H7.801v41.793h-6.24A1.56 1.56 0 0 0 0 89.739c0 .865.698 1.564 1.562 1.564h105.615c.863 0 1.561-.699 1.561-1.564 0-.864-.698-1.563-1.561-1.563Z"
            />
        </g>
    </svg>
);
export default InstitutionIcon;
