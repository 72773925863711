import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const Title = ({ size = 3, children, className, ...props }) => {
    const classes = classNames(className);

    switch (size) {
        case 1:
            return (
                <h1 {...props} className={classes}>
                    {children}
                </h1>
            );
        case 2:
            return (
                <h2 {...props} className={classes}>
                    {children}
                </h2>
            );
        case 3:
            return (
                <h3 {...props} className={classes}>
                    {children}
                </h3>
            );
        case 4:
            return (
                <h4 {...props} className={classes}>
                    {children}
                </h4>
            );
        case 5:
            return (
                <h5 {...props} className={classes}>
                    {children}
                </h5>
            );
        case 6:
            return (
                <h6 {...props} className={classes}>
                    {children}
                </h6>
            );
    }
};

Title.propTypes = {
    size: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string
};
