import React from "react";
import FlexContainer from "@components/FlexContainer/FlexContainer";
import { Button } from "@components/Button/Button";
import styles from "./CompaniesInstitutionSection.module.scss";

export const CompaniesInstitutionSection = () => {
    return (
        <FlexContainer
            className={styles.container}
            style={{
                justifyContent: "center",
                alignItems: "center",
                gap: 40,
                margin: "60px 20px",
                flexWrap: "wrap",
            }}
        >
            <Card
                type="Fyrirtæki"
                title="Markviss og heildræn nálgun í sjálfbærnimálum"
                textColor="#4433CD"
                background="#FFDEC6"
                link="/lausnir/fyrirtaeki"
                listItems={[
                    "Mælanlegar og upplýsandi aðgerðir",
                    "Auðveldar upplýsingagjöf",
                    "Leiðandi markmiðasetning",
                    "Aukin samkeppnishæfni",
                ]}
            />{" "}
            <Card
                type="Opinberir aðilar"
                title="Betri yfirsýn yfir sjálfbærnistarf stofnana"
                textColor="#ff3300"
                background="#EEDEFF"
                link="/lausnir/opinberir-adilar"
                listItems={[
                    "Utanumhald yfir sjálfbærnistarf stofnana",
                    "Stuðningur til að ná settum markmiðum",
                    "Mikilvægur samanburður",
                    "Aukin hagræðing og valdefling í starfi",
                ]}
            />
        </FlexContainer>
    );
};

const Card = ({ type, title, listItems, background, textColor, link }) => {
    return (
        <FlexContainer
            column
            className={styles.card}
            style={{
                background: background,
                color: textColor,
            }}
        >
            <h4>{type}</h4>
            <h3>{title}</h3>
            <ul>
                {listItems.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
            <Button style={{ background: textColor }} href={link}>
                Lesa meira
            </Button>
        </FlexContainer>
    );
};
