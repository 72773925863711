import React from "react";
import styles from "./FeatureGridSection.module.scss";
import classNames from "classnames";
export const FeatureGridSection = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.losun}>
                    <img src="/img/feature-grid/losun.svg" />
                </div>
                <div className={styles.starfsanaegja}>
                    <img src="/img/feature-grid/starfsanaegja.svg" />
                </div>
                <div className={styles.launahlutfall}>
                    <img src="/img/feature-grid/launahlutfall.svg" />
                </div>
                <div className={styles.fyrirtaekjabilar}>
                    <img src="/img/feature-grid/fyrirtaekjabilar.svg" />
                </div>
                <div className={styles.kynjahlutfall}>
                    <img src="/img/feature-grid/kynjahlutfall.svg" />
                </div>
                <div className={styles.stefnur}>
                    <img src="/img/feature-grid/stefnur.svg" />
                </div>
            </div>
            <div
                className={classNames(styles.losun, styles.mobileFeatureGrid)}
                style={{ width: "100%", zIndex: 10 }}
            >
                <img
                    src="/img/feature-grid/mobile.svg"
                    style={{ width: "100%", padding: "0px 20px", zIndex: 10 }}
                />
            </div>
        </>
    );
};
