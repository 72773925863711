import * as React from "react";
const AccomplishmentIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 103 87"
        fill="none"
        {...props}
    >
        <mask
            id="accomplishment-a"
            width={230}
            height={220}
            x={8}
            y={65}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: "luminance",
            }}
        >
            <path fill="#fff" d="M8.215 65.405H30.66v21.1H8.215v-21.1Z" />
        </mask>
        <g mask="url(#accomplishment-a)">
            <path
                fill="#009448"
                d="M22.37 66.507c-4.519.426-9.146.752-13.882.752v15.59c0 1.933 1.614 3.656 3.657 3.656h14.633a3.628 3.628 0 0 0 3.657-3.655V65.428h-.109c-2.368.434-4.954.752-7.956 1.079Z"
            />
        </g>
        <mask
            id="accomplishment-b"
            width={230}
            height={310}
            x={40}
            y={56}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: "luminance",
            }}
        >
            <path fill="#fff" d="M40.148 56.87h22.446v29.634H40.148V56.87Z" />
        </mask>
        <g mask="url(#accomplishment-b)">
            <path
                fill="#009448"
                d="M47.325 62.098a78.375 78.375 0 0 1-6.888 1.605v19.138c0 1.94 1.616 3.655 3.657 3.655h14.625c1.933 0 3.657-1.605 3.657-3.655V57.046c-2.26.962-4.519 1.714-6.878 2.576-2.585.862-5.27 1.614-8.173 2.476Z"
            />
        </g>
        <mask
            id="accomplishment-c"
            width={230}
            height={530}
            x={72}
            y={34}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: "luminance",
            }}
        >
            <path fill="#fff" d="M72.394 34.424h22.13v52.08h-22.13v-52.08Z" />
        </mask>
        <g mask="url(#accomplishment-c)">
            <path
                fill="#009448"
                d="M81.847 46.399c-2.586 1.931-5.49 3.764-8.611 5.487-.208.1-.535.317-.744.426V82.84c0 1.941 1.606 3.655 3.648 3.655h14.634a3.622 3.622 0 0 0 3.656-3.655V34.572c-3.448 4.299-7.639 8.28-12.583 11.827Z"
            />
        </g>
        <path
            fill="#009448"
            d="M99.691 2.855C99.482.596 97.005-.692 95.074.388L75.93 10.383c-2.26 1.18-2.26 4.407 0 5.587l6.242 3.338c-2.259 4.298-5.915 8.38-11.296 12.252a71.073 71.073 0 0 1-6.56 4.19c-2.15 1.19-4.418 2.259-6.886 3.338-.217.109-.317.109-.544.218-2.359.962-4.836 1.932-7.52 2.794-.537.217-1.081.317-1.616.535-1.933.643-3.974 1.188-6.024 1.723-1.932.535-3.865.962-5.916 1.396-.644.109-1.288.219-1.823.318-2.151.435-4.301.861-6.56 1.079-2.15.327-4.41.644-6.777.862-.218 0-.427 0-.645.108-2.258.21-4.627.426-6.994.536-2.469.11-5.055.218-7.63.218-3.012 0-5.381 2.467-5.381 5.37 0 3.01 2.477 5.377 5.38 5.377 35.81 0 66.14-10.965 80.657-27.2.109-.108.217-.317.327-.426 1.07-1.296 2.041-2.584 3.002-3.872.119-.22.327-.328.436-.537.753-1.079 1.288-2.148 1.933-3.227l5.806 3.01c2.259 1.18 4.953-.643 4.627-3.23L99.691 2.856Z"
        />
    </svg>
);
export default AccomplishmentIcon;
